import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import TeamSection from "../../components/team-section/TeamSection"
import Layout from "../../components/layout/Layout"
import SubNav from "../../components/sub-nav/SubNav"
import SectionHeading from "../../components/UI/section-heading/SectionHeading"

const query = graphql`
  query {
    allStrapiTeam {
      nodes {
        section_heading {
          data {
            section_heading
          }
        }
        team_member {
          id
          name {
            data {
              name
            }
          }
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          info {
            data {
              info
            }
          }
          image_left
          order
        }
        team_link {
          id
          link_name
          url
        }
      }
    }
  }
`

const Team = () => {
  const teamData = useStaticQuery(query)
  const data = teamData.allStrapiTeam.nodes[0].team_member
  const linkData = teamData.allStrapiTeam.nodes[0].team_link
  const sectionHeading =
    teamData.allStrapiTeam.nodes[0].section_heading.data.section_heading

  return (
    <Layout>
      <SubNav links={linkData} />
      <SectionHeading heading={sectionHeading} />
      <div>
        {data.map(item => {
          return <TeamSection key={item.id} data={item} />
        })}
      </div>
    </Layout>
  )
}

export default Team
